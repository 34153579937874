/* DESKTOP SPECIFIC */
@media screen and (min-width: 769px) {
  .slim-column {
    border-radius: 5px;
    width: 800px; 
    margin: 20px auto;
    padding: 20px; 
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    position: relative;
  }
  .blog-post-image img {
    max-width: 400px; 
    height: 150;
  }
  .blog-post-content{
    font-size: 24px;
  }
  .blog-post-title{
    font-size: 30px;
  }
  .text-overlay{
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 44px;
    margin-top: 16%;
    margin-left: 5%;
    margin-right: 8%;
    text-align: center;
  }
  .intro{
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 44px;
  }
  .greetings-text{
    font-size: 20px;
    margin-bottom: 50px;
  }
}
/* DESKTOP SPECIFIC */




/* global styles */
.App{
  background: linear-gradient(rgba(2,0,36,1) 0%, rgba(44,31,79,1) 100%, rgba(15,27,78,1) 100%);
  background-color: line; 
  display: flex;
  min-height: 100vh;
}

.slim-column{
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #f0f0f0;
  border: none;
}


.image-container img {
  width: 100%;
  height: 100%;
  min-width: 100px; /* Example minimum width */
  min-height: 100px; /* Example minimum height */
}

.text-overlay{
  position: absolute;
  top: 0;
}
.github-button{
  margin-top: 20px;
  
}


/* global styles */




/* MOBILE SPECIFIC */
@media (max-width: 768px) {
  .slim-column {
    width: 340px;
    margin: 20px auto; 
    padding: 20px; 
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    position: relative;
  }
  .text-overlay {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
    margin-top: 20%;
    margin-left: 5%;
    margin-right: 5%;
}
  .blog-post-title{
    font-size: 18px;
  }
  .blog-post-image img {
    max-width: 200px; 
    height: 150px; 
  }
  .blog-post-text {
    font-size: 12px;
  }
  .intro{
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 20px;
  }
  .greetings-text{
    font-size: 14px;
  }
}
/* MOBILE SPECIFIC */




/* Blog Post CSS */
.blog-title {
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: bold;
}
.blog-post{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.blog-post-wrapper {
  display: flex;
  align-items: center;
}

.blog-post-logos{
  flex: 1;
}

.blog-post-content{
  flex: 2;
  overflow: hidden; 
  text-overflow: ellipsis; 
}
.view-more{
  color: #f0f0f0;
}
/* Blog Post CSS */




/* Kipland Melton*/
t {
  top: 0; 
  left: 0; 
  display: flex;
}
/* Kipland Melton*/




/* NABAR */
.navbar {    
  list-style-type: none;
  position: absolute;
  top: 0; 
  right: 0; 
  display: flex;
  padding-right: 20px;
}

@media (max-width: 768px) {
  .navbar {
    font-size: 10px;
    padding-top: 14px;
  }
}

.navbar li {
  margin-right: 20px; /* Adjust spacing between navbar items as needed */
}

.navbar li:last-child {
  margin-right: 0; /* Remove margin from the last navbar item */
}

.navbar a {
  text-decoration: none;
  color: #f0f0f0; /* Set the text color for the navbar links */
}

.navbar a:hover {
  text-decoration: underline; /* Apply an underline on hover */
}

/* NABAR */
