.container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.heading {
  margin-bottom: 20px;
  color: #ffffff;
}

.list {
  list-style-type: none;
  padding: 0;
}

.listItem {
  margin-bottom: 15px;
}

.link {
  color: #007bff;
  text-decoration: none;
}

